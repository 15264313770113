import request from '@/utils/request'

// 查询社保公积金模板列表
export function listTemplate(query) {
  return request({
    url: '/template/list',
    method: 'get',
    params: query
  })
}

// 查询社保公积金模板详细
export function getTemplate(id) {
  return request({
    url: `/template/info/${id}`,
    method: 'get'
  })
}

// 新增社保公积金模板
export function addTemplate(data) {
  return request({
    url: '/template/save',
    method: 'post',
    data
  })
}

// 修改社保公积金模板
export function updateTemplate(data) {
  return request({
    url: '/template/update',
    method: 'post',
    data
  })
}

// 删除社保公积金模板
export function delTemplate(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/template/delete',
    method: 'post',
    data
  })
}

// 导出社保公积金模板
export function exportTemplate(query) {
  return request({
    url: '/template/export',
    method: 'get',
    params: query
  })
}
