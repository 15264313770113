import request from '@/utils/request'

// 查询会员列表
export function listMember(query) {
  return request({
    url: '/member/list',
    method: 'get',
    params: query
  })
}

// 查询会员详细
export function getMember(id) {
  return request({
    url: `/member/info/${id}`,
    method: 'get'
  })
}

// 新增会员
export function addMember(data) {
  return request({
    url: '/member/save',
    method: 'post',
    data
  })
}

// 修改会员
export function updateMember(data) {
  return request({
    url: '/member/update',
    method: 'post',
    data
  })
}

// 删除会员
export function delMember(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/member/delete',
    method: 'post',
    data
  })
}

// 导出会员
export function exportMember(query) {
  return request({
    url: '/member/export',
    method: 'get',
    params: query
  })
}

export function memberOrderList(query) {
  return request({
    url: '/member/order/list',
    method: 'get',
    params: query
  })
}

export function memberGetInviteList(query) {
  return request({
    url: '/member/getInviteList',
    method: 'get',
    params: query
  })
}

export function memberPayInvite(data) {
  return request({
    url: '/member/payInvite',
    method: 'post',
    data
  })
}
