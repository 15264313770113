<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="城市id" prop="cityId">
        <el-input
          v-model="queryParams.cityId"
          placeholder="请输入城市id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="区域id" prop="regionId">
        <el-input
          v-model="queryParams.regionId"
          placeholder="请输入区域id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="户籍ids" prop="nativeIds">
        <el-input
          v-model="queryParams.nativeIds"
          placeholder="请输入户籍ids"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="模板名称" prop="templateName">
        <el-input
          v-model="queryParams.templateName"
          placeholder="请输入模板名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="基数最小值" prop="baseMin">
        <el-input
          v-model="queryParams.baseMin"
          placeholder="请输入基数最小值"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="基数最大值" prop="baseMax">
        <el-input
          v-model="queryParams.baseMax"
          placeholder="请输入基数最大值"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="每月缴费截止日期" prop="endDate">
        <el-input
          v-model="queryParams.endDate"
          placeholder="请输入每月缴费截止日期"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="允许补缴月份数" prop="payBackMonth">
        <el-input
          v-model="queryParams.payBackMonth"
          placeholder="请输入允许补缴月份数"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="模板类型" prop="templateType">
        <el-select v-model="queryParams.templateType" placeholder="请选择模板类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['system:template:add']"
        >
          新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['system:template:edit']"
        >
          修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['system:template:remove']"
        >
          删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:template:export']"
        >
          导出
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="templateList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="城市id" align="center" prop="cityId" />
      <el-table-column label="区域id" align="center" prop="regionId" />
      <el-table-column label="户籍ids" align="center" prop="nativeIds" />
      <el-table-column label="模板名称" align="center" prop="templateName" />
      <el-table-column label="基数最小值" align="center" prop="baseMin" />
      <el-table-column label="基数最大值" align="center" prop="baseMax" />
      <el-table-column label="每月缴费截止日期" align="center" prop="endDate" />
      <el-table-column label="允许补缴月份数" align="center" prop="payBackMonth" />
      <el-table-column label="详情" align="center" prop="detail" />
      <el-table-column label="模板类型(1:社保模板;2:公积金模板;3:社保个税计算器模板;4:公积个税金计算器模板)" align="center" prop="templateType" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:template:edit']"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:template:remove']"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改社保公积金模板对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="城市id" prop="cityId">
          <el-input v-model="form.cityId" placeholder="请输入城市id" />
        </el-form-item>
        <el-form-item label="区域id" prop="regionId">
          <el-input v-model="form.regionId" placeholder="请输入区域id" />
        </el-form-item>
        <el-form-item label="户籍ids" prop="nativeIds">
          <el-input v-model="form.nativeIds" placeholder="请输入户籍ids" />
        </el-form-item>
        <el-form-item label="模板名称" prop="templateName">
          <el-input v-model="form.templateName" placeholder="请输入模板名称" />
        </el-form-item>
        <el-form-item label="基数最小值" prop="baseMin">
          <el-input v-model="form.baseMin" placeholder="请输入基数最小值" />
        </el-form-item>
        <el-form-item label="基数最大值" prop="baseMax">
          <el-input v-model="form.baseMax" placeholder="请输入基数最大值" />
        </el-form-item>
        <el-form-item label="每月缴费截止日期" prop="endDate">
          <el-input v-model="form.endDate" placeholder="请输入每月缴费截止日期" />
        </el-form-item>
        <el-form-item label="允许补缴月份数" prop="payBackMonth">
          <el-input v-model="form.payBackMonth" placeholder="请输入允许补缴月份数" />
        </el-form-item>
        <el-form-item label="详情" prop="detail">
          <el-input v-model="form.detail" type="textarea" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="模板类型(1:社保模板;2:公积金模板;3:社保个税计算器模板;4:公积个税金计算器模板)">
          <el-select v-model="form.templateType" placeholder="请选择模板类型(1:社保模板;2:公积金模板;3:社保个税计算器模板;4:公积个税金计算器模板)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listTemplate, getTemplate, delTemplate, addTemplate, updateTemplate, exportTemplate
} from '@/api/system/template';

export default {
  name: 'Template',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 社保公积金模板表格数据
      templateList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        cityId: undefined,
        regionId: undefined,
        nativeIds: undefined,
        templateName: undefined,
        baseMin: undefined,
        baseMax: undefined,
        endDate: undefined,
        payBackMonth: undefined,
        detail: undefined,
        templateType: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        cityId: [
          { required: true, message: '城市id不能为空', trigger: 'blur' }
        ],
        regionId: [
          { required: true, message: '区域id不能为空', trigger: 'blur' }
        ],
        nativeIds: [
          { required: true, message: '户籍ids不能为空', trigger: 'blur' }
        ],
        templateName: [
          { required: true, message: '模板名称不能为空', trigger: 'blur' }
        ],
        baseMin: [
          { required: true, message: '基数最小值不能为空', trigger: 'blur' }
        ],
        baseMax: [
          { required: true, message: '基数最大值不能为空', trigger: 'blur' }
        ],
        endDate: [
          { required: true, message: '每月缴费截止日期不能为空', trigger: 'blur' }
        ],
        payBackMonth: [
          { required: true, message: '允许补缴月份数不能为空', trigger: 'blur' }
        ],
        detail: [
          { required: true, message: '详情不能为空', trigger: 'blur' }
        ],
        templateType: [
          { required: true, message: '模板类型(1:社保模板;2:公积金模板;3:社保个税计算器模板;4:公积个税金计算器模板)不能为空', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询社保公积金模板列表 */
    getList() {
      this.loading = true;
      listTemplate(this.queryParams).then((response) => {
        response = response.data;
        this.templateList = response.records;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        cityId: undefined,
        regionId: undefined,
        nativeIds: undefined,
        templateName: undefined,
        baseMin: undefined,
        baseMax: undefined,
        endDate: undefined,
        payBackMonth: undefined,
        detail: undefined,
        templateType: undefined
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '添加社保公积金模板';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getTemplate(id).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = '修改社保公积金模板';
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            updateTemplate(this.form).then((response) => {
              if (response.code === '200') {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addTemplate(this.form).then((response) => {
              if (response.code === '200') {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm(`是否确认删除社保公积金模板编号为"${ids}"的数据项?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => delTemplate(ids)).then(() => {
        this.getList();
        this.msgSuccess('删除成功');
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const { queryParams } = this;
      this.$confirm('是否确认导出所有社保公积金模板数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => exportTemplate(queryParams)).then((response) => {
        this.download(response.data);
      }).catch(() => {});
    }
  }
};
</script>
